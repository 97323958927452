import { useEffect, useRef } from 'react';
import Game from './Game';

function Month({ monthData, monthIndex }) {
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();
  const monthRef = useRef(null);

  useEffect(() => {
    if (currentMonth == monthIndex) {
      // monthRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [currentMonth, monthIndex]);

  if (monthData.games.length === 0) {
    return <></>
  }

  return (
      <div className="month" ref={monthRef}>
        <h2 className="month-title">{monthData.month}</h2>
        { monthData.games.map((game, index) => {
          const gameDay = parseInt(game.date.split('/')[0]);
          const isCurrent = monthIndex === currentMonth && gameDay === currentDate;
          const isPast  = monthIndex <= currentMonth && gameDay < currentDate;
          return <Game key={index} date={game.date} teams={game.teams} score={game.score} isCurrent={isCurrent} isPast={isPast} />;
        }) }
      </div>
  );
}

export default Month;