import "./App.css";
import Month from "./components/Month";
import Scoreboard from "./components/Scoreboard";
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { NameModal } from "./components/NameModal";

const supabase = createClient(
  "https://vzhywrjmvskqjqmbwtlt.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZ6aHl3cmptdnNrcWpxbWJ3dGx0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQxMTg0OTEsImV4cCI6MjAxOTY5NDQ5MX0.VfP050kmMS5WzqFLE8z1GkHJfwkC5Spu1ujRqObp5W0",
);

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [games, setGames] = useState([]);
  const [players, setPlayers] = useState([]);
  const [scoreDict, setScoreDict] = useState({});
  const [gamesDict, setGamesDict] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 900000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    supabase
      .from("games")
      .select()
      .eq("year", "2025")
      .then((response) => {
        const games = response.data[0].games;
        setGames(games);

        const players = Array.from(
          new Set(
            games.flatMap((month) => month.games.flatMap((game) => game.teams)),
          ),
        );
        setPlayers(players);

        const tempScoreDict = {};

        games.forEach((month) => {
          month.games.forEach((game) => {
            game.teams.forEach((team, index) => {
              // Extracting individual names from team string
              let names = team.split("(");
              names.forEach((name) => {
                name = name.replace(")", "").trim(); // Remove the closing parenthesis if any
                if (!tempScoreDict[name]) tempScoreDict[name] = 0; // Initialize the person's point if not done yet
                tempScoreDict[name] += game.score[index]; // Add the score to the person's total points
              });
            });
          });
        });
        setScoreDict(tempScoreDict);

        const tempGamesDict = {};
        games.forEach((month) => {
          month.games.forEach((game) => {
            game.teams.forEach((team, index) => {
              // Extracting individual names from team string
              let names = team.split("(");
              names.forEach((name) => {
                name = name.replace(")", "").trim(); // Remove the closing parenthesis if any
                if (!tempGamesDict[name]) tempGamesDict[name] = 0; // Initialize the person's point if not done yet
                tempGamesDict[name] += 1; // Add the score to the person's total points
              });
            });
          });
        });
        setGamesDict(tempGamesDict);
      });
  }, []);

  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
  const closeScoreBoard = () => {
    setIsScoreboardVisible(false);
  };

  return (
    <div className="App">
      <h1>De Padel Club - {new Date().getFullYear()} 🎾</h1>
      <div id="portal"></div>
      <button
        className="scoreboard-toggle"
        onClick={() => setIsScoreboardVisible(!isScoreboardVisible)}
      >
        {isScoreboardVisible ? "Scorebord" : "Scorebord"}
      </button>

      {isScoreboardVisible && players.length && scoreDict && gamesDict && (
        <Scoreboard
          players={players}
          handleClose={closeScoreBoard}
          scoreDict={scoreDict}
          gamesDict={gamesDict}
        />
      )}

      {games.length &&
        games.map((month, index) => (
          <Month key={index} monthData={month} monthIndex={index} />
        ))}
    </div>
  );
}

export default App;
